import {throttle} from 'lodash-es';

const _compactNavbar = document.getElementById('compact-navbar');
const _mainContent = document.getElementById('main-content');
const _mobileMenu = document.getElementById('mobile-menu');
const compactNavbarHeight = _compactNavbar.offsetHeight;

function checkScrollPosition() {
    if (_mainContent.getBoundingClientRect().y <= 0) {
        _compactNavbar.setAttribute('aria-hidden', 'false');
    } else {
        _compactNavbar.setAttribute('aria-hidden', 'true');
    }
}

window.addEventListener('scroll', throttle(checkScrollPosition, 60));

document.addEventListener('click', (event) => {
    if (event.target.classList.contains('js-mobile-menu-toggle-button')) {
        _mobileMenu.setAttribute('aria-hidden', 'true' === _mobileMenu.getAttribute('aria-hidden') ? 'false' : 'true');
    }
});

document.addEventListener('click', (event) => {
    const hrefValue = event.target.getAttribute('href');

    if (!hrefValue) {
        return;
    }

    const fragment = hrefValue.match(/#.*$/)[0] ?? null;

    if (null === fragment) {
        return;
    }

    if ('#' === fragment) {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });

        return;
    }

    const _target = document.querySelector(fragment);

    if (null === _target) {
        return;
    }

    event.preventDefault();

    if ('false' === _mobileMenu.getAttribute('aria-hidden')) {
        _mobileMenu.setAttribute('aria-hidden', 'true');
    }

    window.scrollTo({
        top: _target.getBoundingClientRect().y + window.scrollY - compactNavbarHeight,
        behavior: 'smooth',
    });
});

